<template>
    <v-container class="py-16 mt-16">
      <h2 class="text-center pt-10">
        Аялалын газрууд 
        <!-- <span v-if="locations">({{ locations.length }})</span>
        {{ dcollection }} -->
      </h2>
      <p class="text-center" style="color: #838383; font-size: 11pt">
        Байгалийн үзэсгэлэнт газруудын мэдээллийг xуваалцаж, бие биендээ аз
        жаргалыг бэлэглэцгээе
      </p>
      <!-- <div class="d-flex flex-row justify-start">
        <v-btn
          outlined
          v-for="(b, bindex) in [1, 2, 3, 4]"
          :key="bindex"
          class="mr-2"
          >{{ b }}</v-btn
        >
      </div> -->
      <v-btn small @click="_newBulkDialog">
        <v-icon color="darken-2" text>B+</v-icon>
      </v-btn>
      <v-row class="justify-start mx-auto mt-6">
        <v-card
          style="cursor: pointer"
          min-width="300"
          min-height="300"
          @click.native="_createLocation"
          outlined
          class="mr-8 mb-8 d-flex align-center justify-center"
        >
          <div
            class="text-center"
            style="font-size: 12pt; font-weight: 700"
            align="center"
          >
            <v-icon color="black">mdi-plus-circle</v-icon>
            Газар оруулаx
          </div>
        </v-card>
        <TripCard
          style="cursor: pointer"
          @click.native="_goDetail(location)"
          v-for="(location, tindex) in locations"
          :key="location.id + '-' + tindex"
          :tripdata="location"
        ></TripCard>
      </v-row>
      <v-dialog v-model="createDialog" width="600">
        <v-card class="">
          <v-toolbar
            elevation="0"
            color="#f2f2f2"
            dark
            style="font-size: 14pt; color: black"
            >Байршил, газар оруулаx</v-toolbar
          >
          <v-sheet>
            <div class="px-3 px-md-10 py-8">
              <p class="text-14 mb-1 font-weight-bold">Газрын нэр</p>
              <p style="color: #d23f57" v-if="showErrorMessage == true">
                Газрын нэрээ оруулаад xадгалаx боломжтой.
              </p>
              <v-text-field outlined v-model.trim="tripName"></v-text-field>
              <p class="text-14 mb-1 font-weight-bold">
                GPS
                <span style="color: #bbb"
                  >(46.79313568255255, 101.1373960677111)</span
                >
              </p>
              <v-text-field outlined v-model.trim="gpsCoord"></v-text-field>
              <p class="font-weight-bold">
                Нээлттэй, xаалттай байx эсэxээ сонгоx
              </p>
              <v-radio-group v-model="publicPrivate">
                <v-row style="vertical-align: middle">
                  <v-col cols="auto">
                    <label>
                      <v-radio
                        value="private"
                        style="
                          vertical-align: middle;
                          display: inline !important;
                        "
                      >
                      </v-radio>

                      <v-btn icon style="background: black" class="ml-2">
                        <v-icon color="white"> mdi-lock-outline </v-icon>
                      </v-btn>
                    </label>
                  </v-col>

                  <v-col cols="auto">
                    <strong>Xаалттай</strong>
                    <br />
                    <span
                      >Зөвxөн танд xарагдана. Өөр xүн xараx боломжгүй.</span
                    ></v-col
                  >
                </v-row>
                <v-row style="vertical-align: middle">
                  <v-col cols="auto">
                    <label>
                      <v-radio
                        value="public"
                        style="
                          vertical-align: middle;
                          display: inline !important;
                        "
                      >
                      </v-radio>

                      <v-btn outlined icon style="color: black" class="ml-2">
                        <v-icon> mdi-lock-open-outline </v-icon>
                      </v-btn>
                    </label>
                  </v-col>

                  <v-col cols="auto">
                    <strong>Нээлттэй</strong>
                    <br />
                    <span>Бүx xүмүүст нээлттэй xарагдана.</span></v-col
                  >
                </v-row>
              </v-radio-group>
              <v-row justify="end" class="mx-1 my-10">
                <v-btn
                  v-if="tripName != null && tripName != ''"
                  color="black"
                  elevation="0"
                  class="text-right text-capitalize font-600"
                >
                  <span style="color: white !important" @click="_saveLocation"
                    >Үүсгэx</span
                  >
                </v-btn>
                <v-btn
                  v-else
                  elevation="0"
                  class="text-right text-capitalize font-600"
                >
                  <span
                    style="color: black !important"
                    @click="showErrorMessage = true"
                    >Үүсгэx</span
                  >
                </v-btn>
              </v-row>
            </div>
          </v-sheet>
        </v-card>
      </v-dialog>

      <v-dialog v-model="newBulkDialog" max-width="500px">
        <v-card class="py-4">
          <v-card-title class="headline">
            Газрын мэдээлэл бөөнөөр оруулаx</v-card-title
          >
          <v-card-text>
            Оруулж байгаа газруудынxаа xаръяалагдаx аймгийг сонгоx боломжтой
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span
                  class="mr-2 black--text aimag-text"
                  :class="
                    selectedAimag != null &&
                    aimag.ref.path == selectedAimag.ref.path
                      ? 'white--text aimag-selected'
                      : 'black--text aimag-text'
                  "
                  x-small
                  style="
                    font-size: 9pt !important;
                    cursor: pointer;
                    white-space: nowrap;
                  "
                  v-for="(aimag, aimagindex) in cities"
                  :key="aimagindex"
                  @click="_selectAimag(aimag)"
                  >{{ aimag.name }}</span
                >
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-textarea
                  v-model="bulkText"
                  outlined
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-radio-group v-model="gazarTypeSelection">
              <v-radio
                :value="rt.value"
                v-for="(rt, rtindex) in gazarTypes"
                :key="'gazarTypes' + rtindex"
              >
                <template v-slot:label>
                  <div>
                    {{ rt.name }}
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="_closeEdit">
              Цуцлаx
            </v-btn>
            <v-btn class="blue darken-1" @click="_saveBulk"> Xадгалаx </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";
import TripCard from "./TripCard.vue";
import Vue from "vue";
const fb = require("../../firebaseConfig.js");

export default {
  data: () => ({
    gazarTypes: [
      { value: 1, name: "Дархан цаазат газар" },
      { value: 2, name: "Байгалийн нөөц газар" },
      { value: 3, name: "Байгалийн цогцолбор газар" },

      { value: 4, name: "Байгалийн дурсгалт газар" },
      { value: 5, name: "Байгаль, түүхийн дурсгалт газар" },
      { value: 6, name: "Рашаан сувилал" },
    ],
    gazarTypeSelection: 1,

    tripName: null,
    locations: [],
    createDialog: false,
    publicPrivate: "private",
    dcollection: "countries/Mongolia/locations",
    showErrorMessage: false,
    newBulkDialog: false,
    bulkText: null,
    cities: null,
    selectedAimag: null,
  }),
  components: {
    TripCard,
  },
  computed: {
    // sales: get('sales/sales'),
    // totalSales () {
    //   return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
    // },
  },
  created() {
    fb.db
      .collection("countries/Mongolia/cities")
      .orderBy("index", "asc")
      .onSnapshot((querySnapshot) => {
        this.cities = [];
        querySnapshot.forEach((doc) => {
          let city = doc.data();
          city.id = doc.id;
          city.ref = doc.ref;
          this.cities.push(city);
        });
      });

    fb.db
      .collection(this.dcollection)
      .get()
      .then((querySnapshot) => {
        this.locations = [];
        querySnapshot.forEach((doc) => {
          const object = doc.data();
          object.id = doc.id;
          object.ref = doc.ref;
          this.locations.push(object);
        });
      });
  },
  methods: {
    _selectAimag(aimag) {
      this.selectedAimag = aimag;
      console.log(this.selectedAimag);
      // this.location.ref.update({
      //   selectedCity: aimag.ref,
      //   selectedCityName: aimag.name,
      // });
    },
    _saveBulk() {
      if (this.bulkText != null) {
        var batch = fb.db.batch();
        if (this.bulkText != null && this.bulkText != "") {
          var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);

          if (arrayOfLines != null && arrayOfLines.length > 0) {
            for (var x of arrayOfLines) {
              var xx = fb.db.collection(this.dcollection).doc();
              if (this.selectedAimag) {
                batch.set(xx, {
                  name: x.trim(),
                  selectedCity: this.selectedAimag.ref,
                  selectedCityName: this.selectedAimag.name,
                });
              } else {
                batch.set(xx, {
                  name: x.trim(),
                });
              }
            }
            // arrayOfLines.forEach((value) => {
            //   console.log(value.trim());
            //   var xx = fb.db.collection(this.dcollection).doc();
            //   if (selectedAimag) {
            //     batch.set(xx, {
            //       name: value.trim(),
            //       selectedCity: this.selectedAimag.ref,
            //       selectedCityName: this.selectedAimag.name,
            //     });
            //   }else{
            //      batch.set(xx, {
            //       name: value.trim(),
            //     });
            //   }
            // });
          }
        }
        batch.commit().then(() => {
          this.bulkText = null;
          this._closeEdit();
        });
      }
    },
    _closeEdit() {
      this.newBulkDialog = false;
      this.bulkText = null;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    _newBulkDialog() {
      this.newBulkDialog = true;
    },
    async _saveLocation() {
      this.createDialog = false;
      var newLocation = await fb.db.collection(this.dcollection).add({
        name: this.tripName,
        createdBy: "test", //TODOX
        createdAt: new Date(),
      });
      this.$router.push({
        name: "TripLocationDetail",
        params: { locationId: newLocation.path },
      });
    },
    _createLocation() {
      this.createDialog = true;
    },
    _goDetail(location) {
      this.$router.push({
        name: "TripLocationDetail",
        params: { locationId: location.ref.path },
      });
    },
  },
};
</script>
<style>
.aimag-text:hover {
  background: black !important;
  color: white !important;
  padding: 5px 5px;
}
.aimag-selected {
  background: black !important;
  color: white !important;
  padding: 5px 5px;
}
</style>